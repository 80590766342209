<template>
  <div class="inspectAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="任务名称:" prop="inspectName">
            <el-input type="text" v-model="inform.inspectName" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="描述:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入描述"></el-input>
          </el-form-item>
          <el-form-item label="巡检周期:" prop="inspectCycle">
            <el-radio-group v-model="inform.inspectCycle">
              <el-radio v-for="(item,index) in dicObj.cycleData" :key="index" :label="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="巡检人员:" prop="inspectUser">
            <el-select v-model="inform.inspectUser" placeholder="请选择巡检人员">
              <el-option v-for="(item,index) in userList" :key="index" :label="item.name" :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用:" prop="isEnable">
            <el-switch v-model="inform.isEnable" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="巡检项目:" prop="inspectItems">
            <div class="item flex-aic" v-for="(item,index) in inform.inspectItems">
              <div class="inputDiv">
                <el-input v-model="item.itemName" placeholder="巡检项名称"></el-input>
              </div>
              <el-button type="text" @click="delItem(index)" class="delBtn error ml10" icon="el-icon-remove-outline"></el-button>
            </div>
            <div class="addBtn" @click="addItem()">新增一行</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        inspectName:'',//巡检名称
        remark:'',//描述
        inspectCycle:'',//巡检周期
        inspectUser:'',//巡检人
        isEnable:'0',//是否启用
        inspectItems:[],//巡检项
      },
      userList :[],

      rules :{
        inspectName: [
          { required: true, message: '请输入巡检名称', trigger: 'blur' },
        ],
        inspectCycle: [
          { required: true, message: '请选择巡检周期', trigger: 'change' },
        ],
        inspectUser: [
          { required: true, message: '请选择巡检人', trigger: 'change' },
        ],
        isEnable: [
          { required: true, message: '请选择是否启用', trigger: 'change' },
        ],
        inspectItems: [
          { required: true, message: '请输入巡检项目', trigger: 'blur' },
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      await vm.inspectGetPerson()
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建巡检任务'
        }else if(type == 'edit'){
          vm.dioTit = '编辑巡检任务'
          vm.detailInspect(item.id)
        }
      })
    },
    //获取场景详情
    async detailInspect(id){
      const res = await apiUrl.detailInspect(id)
      if(res?.code==200){
        vm.inform = {
          id:res.data.id,
          inspectName:res.data.inspectName,//巡检名称
          remark:res.data.remark,//描述
          inspectCycle:`${res.data.inspectCycle}`,//巡检周期
          inspectUser:res.data.inspectUser,//巡检人
          isEnable:`${res.data.isEnable}`,//是否启用
          inspectItems:res.data.inspectItems,//巡检项
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //新增检查项
    addItem(){
      vm.inform.inspectItems.push({
        id:'0',
        itemName:''
      })
    },
    //移除检查项
    delItem(index){
      vm.inform.inspectItems.splice(index,1)
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addInspect()
          }else if(vm.dioType=='edit'){
            vm.updateInspect()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.userList = []
      vm.inform = {
        id:'',
        inspectName:'',//巡检名称
        remark:'',//描述
        inspectCycle:'',//巡检周期
        inspectUser:'',//巡检人
        isEnable:'0',//是否启用
        inspectItems:[],//巡检项
      }
      vm.isDio = false
    },
    //新增
    async addInspect(){
      if(vm.checkNamesNotEmpty(vm.inform.inspectItems)==false) return vm.$message.error('请填写巡检项')
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'inspectName':vm.inform.inspectName,//名称
        'remark':vm.inform.remark,//备注
        'inspectCycle':vm.inform.inspectCycle,//周期
        'isEnable':vm.inform.isEnable,//是否执行
        'inspectUser':vm.inform.inspectUser,//巡检人
        'inspectItems':vm.inform.inspectItems,//执行设备
      }
      const res = await apiUrl.addInspect(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateInspect(){
      if(vm.checkNamesNotEmpty(vm.inform.inspectItems)==false) return vm.$message.error('请填写巡检项')
      let dataObj = {
        'id':vm.inform.id,
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'inspectName':vm.inform.inspectName,//名称
        'remark':vm.inform.remark,//备注
        'inspectCycle':vm.inform.inspectCycle,//周期
        'isEnable':vm.inform.isEnable,//是否执行
        'inspectUser':vm.inform.inspectUser,//巡检人
        'inspectItems':vm.inform.inspectItems,//执行设备
      }
      const res = await apiUrl.updateInspect(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取巡检人
    async inspectGetPerson (){
      let csType = vm.$store.state.csType
      const res = await apiUrl.ticketGetPerson(csType)
      if(res?.code==200){
        res.data?.forEach(item =>{
          if(item.personName){
            item.name = item.account+'('+item.personName+')'
          }else{
            item.name = item.account
          }
        })
        vm.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //判断检查项是否存在空值
    checkNamesNotEmpty(arr) {  
      // 遍历数组  
      for (let i = 0; i < arr.length; i++) {  
        // 检查当前对象的name属性是否为空字符串  
        if (arr[i].itemName === "") {  
          // 如果发现有空字符串，则返回false  
          return false;  
        }  
      }  
      // 如果遍历完整个数组都没有发现空字符串，则返回true  
      return true;  
    }
  }
}
</script>
<style lang='scss'>
.inspectAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .item{
      .inputDiv{
        flex-grow: 1;
      }
      .delBtn{
        font-size: 20px;
        flex-shrink: 0;
      }
    }
    .addBtn{
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid #00c6ff;
      color: #00c6ff;
      margin-top: 10px;
      line-height: 26px;
      &:hover{
        box-shadow: 0px -5px 27px 0px rgba(7, 130, 255, 0.55) inset;
      }
    }
  }
}
</style>