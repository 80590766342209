<template>
  <div class='safety-inspect-task mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="90px" inline @submit.native.prevent size="small">
        <el-form-item label="任务名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入任务名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="巡检周期:" prop="inspectCycle">
          <el-select v-model="keyWord.inspectCycle" placeholder="巡检周期" class="w180" clearable @change="getList(1)">
            <el-option v-for="(item,index) in dicObj.cycleData" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:inspect:task:add')"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:inspect:task:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="inspectName" label="任务名称" width="200"/>
          <el-table-column prop="remark" label="描述" show-overflow-tooltip/>
          <el-table-column label="周期" :formatter="formatCycle" width="100"/>
          <el-table-column prop="inspectUserName" label="指派人" width="100"/>
          <el-table-column :formatter="formatTime2" label="创建时间" width="200"/>
          <el-table-column label="操作" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:safety:inspect:task:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:safety:inspect:task:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <inspect-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></inspect-add-edit>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import inspectAddEdit from './inspect-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-inspect-task',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
        inspectCycle:'',
      },
      dicObj:{
        cycleData:[{
          id:'0',
          name:'每天',
        },{
          id:'1',
          name:'每周',
        },{
          id:'2',
          name:'每月',
        },{
          id:'3',
          name:'每季度',
        },{
          id:'4',
          name:'每年',
        }],//周期
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    inspectAddEdit,
    breadCrumb,
  },
  watch:{
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.getList(1)
      }
    }
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
    // vm.getCurrDics()
  },
  mounted(){
    if(vm.$store.state.homeId!=null) vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteInspect(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增、修改
        }
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteInspect(arr);//删除
      }).catch(() => {});
    },
    //删除
    async deleteInspect(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteInspect(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤时间
    formatTime2(row,column){
      return row.createTime?formatTime(row.createTime,'Y-M-D h:m:s'):'-'
    },
    //过滤周期
    formatCycle(row,column){
      let result = vm.dicObj.cycleData.filter(item => item.id == row.inspectCycle)
      return result[0]?.name||'-'
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        "areaId": vm.$store.state.csType,
        "homeId": vm.$store.state.homeId,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits,
        "inspectName": vm.keyWord.name,
        "inspectCycle": vm.keyWord.inspectCycle,
      }
      const res = await apiUrl.inspectListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    // async getCurrDics(){
    //   let dataObj = ['SCENE_ACTION_TYPE','SCENE_TYPE']
    //   const res = await apiUrl.getDicts(dataObj)
    //   if(res?.code==200){
    //     vm.dicObj.actionData = res.data?.SCENE_ACTION_TYPE||[];//场景动作
    //     vm.dicObj.typeData = res.data?.SCENE_TYPE||[];//场景类型
    //   }else{
    //     vm.$message.error(res.message)
    //   }
    // },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safety-inspect-task{

}
</style>
